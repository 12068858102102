<template>
  <section>
    <main class="container">
      <h2>{{ $t("faucet") }}</h2>
      <div class="basic-group">
        <input
          @keydown.enter="giveMeMath"
          v-model="address"
          type="text"
          :placeholder="$t('faucet_address')"
          autocomplete="off"
        />
        <button @click.stop="giveMeMath">{{ $t("send") }}</button>
      </div>
      <p class="result" v-if="result">{{ result }}</p>
    </main>
    <loading :loading="loading" />
  </section>
</template>

<script>
import Loading from "@/base/loading.vue";
export default {
  data() {
    return {
      loading: false,
      address: null,
      result: "",
    };
  },
  components: { Loading },
  methods: {
    giveMeMath() {
      if (this.address && this.address.trim()) {
        this.loading = true;
        this.axios
          .post("https://faucet.maiziqianbao.net/faucet", {
            id: 1,
            jsonrpc: "2.0",
            address: this.address,
          })
          .then((res) => {
            this.loading = false;
            this.result = res.data;
          })
          .catch(() => {
            this.loading = false;
            this.$toast("failed");
            console.log(res);
          });
      } else {
        this.$toast(this.$t("inputAddressFirst"));
      }
    },
  },
};
</script>

<style scoped>
section{
  position: relative;
}
.container {
  max-width: 640px;
}
h2 {
  padding: 30px;
  font: 500 16px Gotham-Medium;
  text-align: center;
}
.basic-group input {
  padding: 8px 52px 8px 12px;
}
.basic-group button {
  padding: 0 12px;
  color: var(--navBg);
  background: #fff;
}
.result {
  margin-top: 20px;
  padding: 12px;
  color: var(--greyColor);
  word-break: break-word;
}
</style>
